import { create } from "zustand"
import { persist } from 'zustand/middleware'

type GeneralStoreState = {
  theme: string,
  faqIsOpen: boolean,
  headerBgColor: string,
  configPaths: any,
  isMobile: boolean
  isHeaderVisible: boolean
  isPremiumUser: boolean
}

type GeneralStoreActions = {
  setTheme: (theme: string) => void,
  setFaqIsOpen: (value: boolean) => void,
  setHeaderBgColor: (headerBgColor: string) => void,
  setConfigPaths: (configPaths: any) => void
  setIsMobile: (value: boolean) => void,
  setIsHeaderVisible: (value: boolean) => void,
  setIsPremiumUser: (value: boolean) => void,
}

// define the initial state
export const initialGeneralStoreState: GeneralStoreState = {
  theme: 'light',
  faqIsOpen: false,
  headerBgColor: 'bg-colorOneNeon',
  configPaths: null,
  isMobile: false,
  isHeaderVisible: true,
  isPremiumUser: false
}

const useGeneralStore = create<GeneralStoreState & GeneralStoreActions>()(
  persist(
    (set, get) => ({
      ...initialGeneralStoreState,

      setTheme: (value) => set({ theme: value }),
      setHeaderBgColor: (value) => set({ headerBgColor: value }),
      setFaqIsOpen: (value) => set({ faqIsOpen: value }),
      setConfigPaths: (value) => set({ configPaths: value }),
      setIsMobile: (value) => set({ isMobile: value }),
      setIsHeaderVisible: (value) => set({ isHeaderVisible: value }),
      setIsPremiumUser: (value) => set({ isPremiumUser: value }),
      reset: () => { set(initialGeneralStoreState) },
    }),
    {
      name: 'general', // name of the item in the storage (must be unique)
    },
  ),
)

export default useGeneralStore;