'use client';

import useGeneralStore from '@/store/general';
import { useEffect, useState } from 'react';

import { getAnalytics, isSupported, logEvent } from "firebase/analytics";
import { getFirebaseApp } from "@/lib/firebase-client-config";


export default function Layout({
    children,
}: {
    children: React.ReactNode,
}) {

    const { isMobile, setIsMobile } = useGeneralStore();


    useEffect(() => {

        // initializeAnalitics()
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    // TODO: analythics doesnt work since it is no longer linked to firebase project. TO BE FIXED
    // const [analytics, setAnalytics] = useState<any>(null);
    // const initializeAnalitics = async () => {
    //     if (await isSupported()) {
    //         const firebaseApp = await getFirebaseApp();
    //         if (firebaseApp) {
    //             setAnalytics(getAnalytics(firebaseApp))
    //         }
    //     }
    // }
    return (
        <> {children}</>
    )
}
